// TODO refactor to obejct or enum
export const CALL_HISTORY = '/callhistory.php';
export const AZURE_INTEGRATION = '/admin/azure_integration.php';
export const CALL_CENTER_QUEUES = '/admin/call_center_queues.php';
export const CALL_CENTER_AGENTS = '/admin/call_center_agents.php';
export const IVR_MENUS = '/admin/ivr_menus.php';
export const IVR_MENU_LANGUAGE_OPTIONS = `${IVR_MENUS}?get=language_options`;
export const ADMIN_RECORDINGS = '/admin/recordings.php';
export const ADMIN_PHRASES = '/admin/phrases.php';
export const ADMIN_MUSIC_ON_HOLD = '/admin/music_on_hold.php';
export const ADMIN_RING_GROUPS = '/admin/ring_groups.php';
export const ADMIN_PICKUP_GROUPS = '/admin/pickup_groups.php';
export const ADMIN_EXTENSIONS = '/admin/extensions.php';
export const ADMIN_USERS = '/admin/users.php';
export const CONTACTS = '/contacts.php';
export const HOMEPAGES = '/homepages.php';
export const USERS = '/users.php';
export const CALL_CENTER = '/callcenter.php';
export const CONTACT_AVATAR = '/contact_attachments.php';
export const CAPABILITIES = '/capabilities.php';
export const DOMAIN_OPTIONS = '/domain_options.php';
export const EXTENSIONS_SETTINGS = '/extension_settings.php';
export const SECRETARY_STATUS = '/is_secretary.php';
export const CALL_FORWARD_EXTENSION_SETTINGS = '/extension_forwards.php';
export const FOLLOW_ME_EXTENSION_SETTINGS = '/extension_follow_me.php';
export const FAVORITES = '/favorites.php';
export const VOICEMAIL = '/voicemail.php';
export const PARKED_CALLS = '/parking.php';
export const ADMIN_RING_GROUP = '/admin/user_ring_groups.php';
export const LOGIN = '/login.php';
export const ACCOUNT = '/account.php';
export const LOG_REPORT = '/logreport.php';
// todo verify if the name make sense
export const CALL_CONFERENCE = '/conference.php';
export const TRANSFER = '/transfer.php';
export const CALLCENTER_CALL_HISTORY = '/v1/dashboard/call_history.php';
